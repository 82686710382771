@import '../../styles/colors';

header {
    width: 100%;
    height: 60px;
    background: radial-gradient(720.00px at 50% 68.33%, #4E86E4 0%, #2F68C8 100%);
    box-shadow: 0px 1px 5px #C1CEDE;

    &>.container {
        height: 100%;
        @extend %ct;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .logo-img {
            margin-left: 15px;
        }

        .active {
            svg {
                path {
                    fill: $white !important;
                }
            }

            p {
                color: $white;
            }

            img {
                -webkit-filter: brightness(1.5);
                -moz-filter: brightness(1.5);
                -ms-filter: brightness(1.5);
                filter: brightness(1.5);
            }
        }

        a {
            margin: 0 60px 0 0;

            img {
                display: inline-block;
            }

            p {
                margin: 0 5px;
                padding: 0;
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: $gray;
                display: inline-block;
                transition: all 0.1s ease-in;

            }

            &:hover {
                svg {
                    path {
                        fill: $white !important;
                    }
                }

                p {
                    color: $white;
                }

                img {
                    -webkit-filter: brightness(1.5);
                    -moz-filter: brightness(1.5);
                    -ms-filter: brightness(1.5);
                    filter: brightness(1.5);
                }
            }
        }
    }
}

@media (max-width: 540px) {
    header {
        a {
            margin: 0 15px 0 0 !important;
        }

        p {
            width: 27px;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}