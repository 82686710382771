@import '../../../styles/colors';

.my-select {
    border-color: $white_dark;

    input {
        font-size: 14px;
        line-height: 16px;
    }
    font-family: 'Roboto', sans-serif;

    .my-select__control {
        max-width: 300px;
        border-radius: 0;

        .my-select__indicators {
            .my-select__clear-indicator {
                display: none;
            }

            .my-select__indicator-separator {
                display: none;
            }

            .my-select__dropdown-indicator {
                color: $gray_dark;
            }
        }

        .my-select__input {
            
            font-size: 14px;
            line-height: 16px;
            width: 100px;
        }
    
        .my-select__value-container {
            .my-select__single-value  {
                position: absolute;
                top: 38px;
                left: 8px;
                font-size: 14px;
                line-height: 16px;
                height: 100%;
            }

            div {
                height: 100%;
            }

            .my-select__placeholder {
                position: absolute;
                top: 20px;
                padding: 18px 0 0 0;
                font-size: 14px;
                line-height: 16px;
                height: 100%;
            }

            div:last-child {
                height: 30px;
            }
        }

        .my-select__value-container--is-multi {
            .my-select__multi-value {
                position: relative;
                top: 7px;
                background: $gray;
                height: 24px;

                .my-select__multi-value__label {
                    height: 100%;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    color: $gray_dark;
                }

                .my-select__multi-value__remove {
                    height: 100%;
                    color: $gray_dark;
                }
            }
        }
    }

    .my-select__menu {
        margin: 15px 0 0 0;
        border-radius: 0;

        .my-select__menu-list {
            max-height: 175px;

            .my-select__option{
                color: $black_light;
                font-size: 14px;
                line-height: 16px;
            }

            .my-select__option--is-selected {
                background: $gray;
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px transparent;
                box-shadow: 0;
                background-color: transparent;
            }
            
            &::-webkit-scrollbar{
                width: 3px;
                background-color:  transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $white_dark;

                &:hover {
                    background-color: $blue_dark;
                }
            }
        }
    }
}

.my-select-not-visited {
    .my-select__control {
        .my-select__value-container {
            .my-select__placeholder {
                top: 14px;
            }

            .my-select__single-value {
                top: 32px;
                left: 8px;
            }
        }
        .my-select__value-container--is-multi {
            .my-select__multi-value {
                top: -1px;
            }
        }
    }
    
}

@media (max-width: 650px) {
    .my-select {
        width: 100% !important;

        .my-select__control {
            max-width: 100% !important;
            
            .my-select__control {
                max-width: 100% ;
            }
        }
    }
}