.birth-date {
    max-width: 192px;
    height: 40px;

    input {
        width: 100%;
    }

    .error {
        position: relative;

        .calendar-icon {
            pointer-events: none;
            position: absolute;
            right: 10px;
            top: 17px;
        }
    }
}