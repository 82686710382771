@charset 'utf-8';
@import '../../styles/colors';

.container {
    position: relative;
    @extend %ct;

    h1 {
        padding: 0;
        margin: 45px 0 !important;
        font-size: 35px;
        line-height: 41px;
        text-align: center;
        color: $black;
    }

    .required {
        position: relative;

        &:after {
            color: $gray_dark;
            position: absolute;
            content: '*';
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            top: 10px;
            right: 2px;
        }
    }

    .accountForm, .profileForm, .contactsForm, .capabilitiesForm {
        display: flex;
        justify-content: space-around;
        padding: 60px 0;

        .columns {
            display: flex;
            flex-direction: column;
        }
    }

    .load-from-storage {
        z-index: 1;
        position: absolute;
        top: 148px;
        width: 100%;
        box-sizing: border-box;
        font-family: inherit;
        padding: 0 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 42px;
        background: $blue_popup;
        color: $white;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;

        .btn-small {
            font-family: inherit;
            font-size: 14px;
            line-height: 16px;
            font-weight: 900;
            color: $white;
        }

        .close {
            margin-left: auto;
        }
    }
}

@media (max-width: 650px) {
    .container {
        .h1-edit-user {
            margin-left: 170px !important;
        }

        .h1-add-user {
            margin-left: 0 !important;
        }

        .accountForm, .profileForm, .contactsForm, .capabilitiesForm {
            flex-direction: column;
        }

        .item {
            margin: 0 5%;

            input {
                width: 100% !important;
            }
        }
    }
}