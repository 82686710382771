.edit-item {
    padding: 25px 0;

    .columns {
        .rows {
            margin-bottom: 16px;

            .hobbies-list {
                position: relative;
                top: -5px;
                span {
                    display: block;
                    padding: 5px 0;
                }
            }

            span {
                min-width: 230px;
            }
        }
    }

    h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin: 0 10px;
    }

    p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        min-width: 170px;
        margin: 0;
    }

    span {
        max-width: 230px;
        word-wrap: break-word;
    }

    button {
        position: relative;

        .edit-button {
            position: absolute;
            top: 0;
        }

    }
}

@media (max-width: 950px) {

    .edit-item {
        justify-content: center;

        .section {
            h5 {
                margin: 0;
                font-size: 20px;
            }

            display: flex;
            justify-content: flex-start;
            padding: 0 0 20px 0;
            font-size: 25px;
        }
    }
}

@media (max-width: 650px) {
    .edit-item {
        flex-direction: column;
    }
}