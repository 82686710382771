@import '../../../../styles/colors';

.item-buttons {
    display: flex;
    justify-content: space-between;

    button:only-child {
        margin-left: auto;
    }

    .back-btn {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $white;
        width: 100px;
        height: 40px;
        background: $white_dark;

        &:hover {
            background: darken($white_dark, 20);
        }
    }

    .btn {
        width: 100px;
    }

    .btn-finish {
        background: $green;
    }
}