@charset 'utf-8';
@import 'colors', url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    color: $black_light;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    
    .navigation-link {
        font-family: 'Roboto';
        position: absolute;
        top: -10px;
        font-size: 18px;
        line-height: 18px;
        color: $gray_dark;
        text-decoration: none;
        transform: translate(20px);

        &:visited {
            color: $gray_dark;
        }

        &:hover {
            color: $black;

            &:after {
                border-color: $black;
            }
        }

        &:after {
            content: '';
            position: absolute;
            left: -8px;
            right: calc(100%);
            top: calc(50% - 4px);
            bottom: calc(50% - 4px);
            border: 2px solid #9BB0CB;
            border-top: none;
            border-right: none;
            box-sizing: content-box;
            transform: translate(-10px);
            transform: rotate(45deg);
        }
    }

    .loading {
        color: $black;
        font-size: 35px;
        line-height: 41px;
        margin: 56px 0 28px 0;
        padding: 0;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }

    p {
        margin: 0;
        padding: 0;
    }

    input {
        background: $white;
        border: 1px solid $white_dark;
        box-sizing: border-box;
        width: 300px;
        height: 40px;
        padding: 10px;
    }    
}