@import '../../../styles/colors';

.item {
    padding: 10px 0;

    .error {
        position: relative;
        color: $red;
        font-size: 9px;
        line-height: 11px;
        padding-top: 5px;

        .error-text {
            position: absolute;
            margin: 0 0 0 10px;
            padding: 2px;
            display: block;
        }
    }
}