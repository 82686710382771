@import '../../../styles/colors';

.capabilitiesForm {
    .info-textarea {
        display: flex;
        flex-direction: column;

        textarea {
            margin-top: 5px;
            border: 1px solid $white_dark;
            resize: none;
            box-sizing: border-box;
            width: 300px;
            height: 100px;
            padding: 10px;
            font-family: 'Roboto';

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px transparent;
                box-shadow: 0;
                background-color: transparent;
            }

            &::-webkit-scrollbar{
                width: 3px;
                background-color:  transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $white_dark;

                &:hover {
                    background-color: $blue_dark;
                }
            }
        }

        input[type="textarea"] {
            width: 300px;
            height: 100px;
            word-wrap: break-word;
            white-space: pre-wrap;
        }
    }

    .checkbox {
        p {
            padding-top: 3px;
        }

        label {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        input[type="checkbox"] {
            margin: 0 10px 0 0;
            cursor: pointer;
            width: 13px;
            height: 13px;
            background: $white;
        }

        input[type="checkbox"]:before {
            position: absolute;
            top: 2px;
            left: -2px;
            background: $white;
            border: 1px solid $white_dark;
            box-sizing: border-box;
            content: "\00a0";
            display: inline-block;
            font: 16px/1em sans-serif;
            height: 16px;
            margin: 0 00 0 0;
            padding: 0;
            vertical-align: top;
            width: 16px;
        }

        input[type="checkbox"]:checked:before {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $white_dark;
            content: "\002B1B";
            font-size: 13px;
            color: transparent;
            text-shadow: 0 0 0 $blue_dark;
        }
    }
}

@media (max-width: 650px) {
    .capabilitiesForm {
        .info-textarea {
            textarea {
                width: 100% !important;
            }
        }

        .checkbox {
            p {
                padding-left: 10px;
            }
        }
    }
}