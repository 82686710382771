@import '../../../../styles/colors';

.placeholder-preview {
    padding: 10px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profilePic {
    margin-bottom: 15px;
    width: 165px;
    height: 165px;
    border-radius: 50%;
    border: 3px solid $blue_dark;
    overflow: hidden;

    .emptyPic {
        padding-top: 15px;
        width: 165px;
        height: 165px;
    }
}

.error {
    .img {
        left: 10px;
        bottom: -15px;
    }

    .pic-red {
        border: 2px solid $red !important;
    }

    .input-error {
        border: 1px solid $red;
    }
}

@media (max-width: 650px) {
    .img {
        left: 50% !important;
        transform: translate(-50%);
        white-space: nowrap;
    }
}