@import '../../styles/colors';

.tabs {
    background: rgba($gray, .2);

    .tab-content {
        min-height: 50vh;
        padding: 10px 0;
    }

    .tab-list {
        margin: 0;
        padding: 0;
        display: flex;
    }
}