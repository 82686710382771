@import '../../../styles/colors';

.radio {
    .error {
        display: flex;
        color: inherit;

        .error-label {
            display: flex;
            align-items: center;
            padding: 5px;

            span {
                color: $black_light !important;
            }

            input {
                margin: 0;
                padding: 0;
            }

            span {
                margin: 0 5px;
                padding: 0;
                font-size: 14px;
                line-height: 16px;
                top: 2px;
            }
        }

        .error-text {
            top: 25px;
            color: $red;
        }
    }

    div {
        margin: 0 40px 0 0;
    }

    input[type='radio'] {
        width: 17px;
        height: 17px;
        position: relative;

        &:before {
            position: absolute;
            content:'';
            display: block;
            width: 60%;
            height: 60%;
            top: 15%;
            left: 3px; 
            border-radius:50%;    
        }

        &:checked:before {
            background:$blue_dark;
        }
    }
}