@charset 'utf-8';
@import '../../styles/colors';

.user-profile {
    .columns {
        display: flex;
        flex-direction: column;

        .profilePic {
            margin-bottom: 15px;
            width: 200px;
            height: 200px;
            border-radius: 50%;
            border: 3px solid $blue_dark;
            overflow:hidden;

            .emptyPic {
                padding-top: 25px;
                width: 200px;
                height: 200px;
            }
        }
    }

    .rows {
        display: flex;
        min-width: 160px;
    }

    .content {
        background: rgba($gray, 0.2);
        display: flex;
        justify-content: space-around;
        padding: 35px 60px;

        .columns {
            min-width: 300px;

            .user-logo {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                border: 3px solid $blue_dark;
            }
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px transparent;
            box-shadow: 0;
            background-color: transparent;
        }

        &::-webkit-scrollbar{
            height: 3px;
            background-color:  transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $white_dark;

            &:hover {
                background-color: $blue_dark;
            }
        }
    }
}

@media (max-width: 950px) {
    .user-profile {
        .content {
            overflow-x: auto;
            flex-direction: column;

            .columns {
                img {
                    align-self: center;
                    margin-bottom: 40px;
                }
            }
        }
    }
}

@media (max-width: 650px) {
    .user-profile {
        .h1-profile {
            text-align: end;
            margin-right: 15px !important;
        }
    }
}