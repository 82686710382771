@charset 'utf-8';

%ct {
    min-height: inherit;
    max-width: 970px;
    margin: 0 auto;
    padding: 0;
}

$white: #fff;
$white_dark: #C1CFE0;
$gray: #E7F0FF;
$gray_dark: #9BB0CB;
$black: #475666;
$black_light: #657C9A;
$blue: #97BAF4;
$blue_dark: #4E86E4;
$red: #EB5757;
$green: #4EE4A5;
$blue_popup: #5E97F3;