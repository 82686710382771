@charset 'utf-8';
@import '../../styles/colors';

.container {
    @extend %ct;
    position: relative;
    font-family: inherit;

    .add-user {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }

    .list-h1 {
        margin: 56px 0 28px 0;
        padding: 0;
    }

    .search-items {
        width: 100px;
        position: absolute;
        right: 0;
        top: 0;
        transition: width 0.2s;

        &:focus {
            width: 250px;
        }
    }

    .not-empty {
        width: 250px;
    }
}

@media (max-width: 975px) {
    .search-items {
        margin-right: 10px;
    }
}

@media (max-width: 1110px) {
    .list-h1 {
        padding-left: 10px;
        text-align: start;
    }
}