.item-phone {
    position: relative;

    .del-phone {
        position: absolute;
        left: calc(100% + 10px);
        top: 40px;
    }

    .add-phone {
        display: flex;
    }
}

@media (max-width: 650px) {
    .item-phone {
        .item {
            width: 80%;
        }

        .del-phone {
            left: calc(85% + 10px) !important;
        }

        &:only-child {
            .item {
                width: 90% !important;
            }
        }
    }
}