.passwordField {
    position: relative;
}

.passwordField .btn-small {
    position: absolute;
    top: 50%;
    right: 5px;
}

@media (max-width: 650px) {
    .passwordField button {
        right: calc(5% + 5px) !important;
    }
}
