@import '../../styles/colors';

button {
    border: none;
    font-family: inherit;
    cursor: pointer;
}

.btn {
    padding: 12px 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    background: $blue_dark;
    color: $white;

    &:hover {
        background: darken($color: $blue_dark, $amount: 20)
    }

    &:focus {
        outline: none;
    }
}

.btn-small {
    color: $gray_dark;
    background: transparent;

    &:hover {
        color: $blue_dark;
    }

    &:focus {
        outline: none;
    }
}