@import '../../../styles/colors';

.tab-list-item {
    user-select: none;
    display: flex;
    width: 25%;
    justify-content: center;
    color: $black;
    height: 62px;
    background: rgba($blue, 0.2);
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;   
    transition: all 0.05s ease-in;
    white-space:nowrap;
    overflow:hidden;
    transition: width 0.5s !important;

    &:hover {
        background: $blue_dark;
        color: $white;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        color: $gray_dark;
        cursor: auto;

        &:hover {
            background: rgba($blue, 0.2);
        }
    }
}

.tab-list-active {
    background: $blue_dark;
    color: $white;

    .tab-label {
        display: flex;
    }
}

@media (max-width: 650px) {
    .tab-list-item {
        width: 15%;

        .tab-label {
            display: none;
        }
    }

    .tab-list-active {
        width: calc(55%);

        .tab-label {
            display: flex;
        }
    }
}