@import '../../styles/colors';

.table-wrapper {
    overflow-x: visible;
    padding-bottom: 30px; 

    .list-of-users {
        box-sizing: border-box;
        width: 100%;
        min-width: 700px;
        border-collapse: collapse;
        margin: 0 auto;

        thead {
            background: $blue_dark;

            td {
                text-align: start;
                color: $white;
                padding: 14px 0;
                font-weight: 500;
                &:first-child {
                    padding: 0 70px;
                }
            }
            margin: 30px;
        }

        tbody {
            transform: translate(0, 30px);
            margin-top: 30px; 

            .item-to-delete {
                transform: translate(-70px);
            }

            button {
                color: $black;
                font-weight: 500;
                text-align: left;
                padding: 0 15px;
                margin: 0;

                figure {
                    margin: 0;
                    padding: 0;
                }
            }

            tr {
                transition: all 0.05s ease-in;
                font-size: 14px;
                line-height: 16px;
                font-weight: 500;
                color: $black;
                height: 93px;

                &:nth-child(odd) {
                    background: $gray;
                }

                .user {

                    figure {
                        display: flex;
                        align-items: center;

                        .info {
                            margin: 0 0 0 15px;

                            .username {
                                font-size: 9px;
                                line-height: 11px;
                            }
                        }

                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                        }
                        display: flex;
                    }
                }

                .delete-container {
                    position: relative;

                    .delete-confirm {
                        position: absolute;
                        right: -70px;
                        color: $red;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px transparent;
        box-shadow: 0;
        background-color: transparent;
    }

    &::-webkit-scrollbar{
        height: 3px;
        background-color:  transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $white_dark;

        &:hover {
            background-color: $blue_dark;
        }
    }
}

@media (max-width: 1110px) {
    .table-wrapper {
        overflow-x: scroll !important;
    }
}