@import '../../styles/colors';

.page-numbers {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 70px auto;

    p {
        position: relative;
        top: 20px;
        width: 50px;
        text-align: center;
    }

    .btn-small  {
        border: 2px solid $gray;
        width: 50px;
        padding: 8px 12px !important;
        color: $black;
        font-size: 20px;
        font-weight: 500;
        padding: 0 15px;
        cursor: pointer;

        &:hover {
            background-color: lighten($blue, 10%);
            color: $blue_dark;
        }
    }

    .active {
        background-color: lighten($blue, 20%);
        color: $blue_dark;
    }
}

.items-per-page {
    margin-left: 15px;
    position: absolute;
    bottom: 70px;
    display: flex;

    select {
        position: relative;
        bottom: 5px;
        width: 55px;
        height: 30px;
        font-size: 18px;
    }

    p {
        font-size: 18px; 
        margin: 0 10px 0 0;
    }
}