.accountForm {
    z-index: 3;
}

.accountForm .load-from-storage {
    z-index: 4;

}
.accountForm .crop-container-active {
    z-index: 4;
}

.accountForm input {
    width: 400px;
} 